import React, { useState, useEffect } from 'react';
import { Provider, observer } from 'mobx-react';
import { configure } from "mobx";
import { Grid, useTheme, Box } from '@mui/material';

import {
  useAppContext,
  Model,
  FormBody,
  CommonAppLayout,
  MainTopbar,
  MinimalTopbar,
  Submenubar,
} from 'clnt-common';

// Either use a FormSidebar or Submenubar but not both
import { SubmenubarList } from './SubmenubarList';

const model = new Model();                                      // <<< This differs across SPAs

// TO PREVENT DUMPING MESSAGES IN CONSOLE LOG
// Model.js?t=1700074595689:241 [MobX] Since strict-mode is enabled,
//  changing (observed) observable values without using an action is not allowed.
//  Tried to modify: ObservableObject@1.state
configure({
    enforceActions: "never",
})

export { App };
const App = props => {
  const theme = useTheme();

  const { appState, dispatch } = useAppContext();

  const SubmenubarComp = <Submenubar buttonList={SubmenubarList(model, theme)} />

  useEffect(() => {
    const init = async () => {
      if ((appState.token || appState.fpid) && appState.upap) {
        await model.initRuntime(appState, dispatch);
	model.setInit(); // Forces a re-render as the state is changed from 'start' to 'init'
      }
      else {
	console.log('App.jsx - Run useEffect - skip as there are no jwt and upap');
      }
    };
    init();
  },[appState.token, appState.fpid, appState.upap]);

  if (!appState.upap) {
    console.log('App.jsx - No upap available, show LOADING...');
    return <><h1>LOADING...</h1> <h2>{appState.emsg}</h2></>;
  }
  else {	
    //console.log('App.jsx - Render');
    return (
	  <Provider store={model}>
	    <CommonAppLayout
              app={'FORM'}
              Body={FormBody}
              Sidebar={null}
	      Submenubar={SubmenubarComp}
              MainTopbar={MainTopbar}
              MinimalTopbar={MinimalTopbar}
	    /> {/* <<< This differs for each SPA */}
	  </Provider>
    );
  }

};

